<script lang="ts">
import type { IconClass } from '#core/types'
import type { Value, ValueFormat } from '#core/utils/format-value'
import type { Component } from 'vue'
import { isVNode } from 'vue'

type MaybeComponent = Component | (() => Component)

export interface StatProps {
  // global
  variant?: 'card' | 'inline'
  align?: 'left' | 'center' | 'right'
  loading?: boolean

  // top
  icon?: IconClass
  title?: string
  tooltip?: string

  // middle
  value?: Value
  format?: string | ValueFormat

  // bottom
  info?: string
  meta?: string | number | MaybeComponent
  action?: IconClass | MaybeComponent

  // components
  left?: MaybeComponent
  right?: MaybeComponent
}
</script>

<script setup lang="ts">
/**
 * UiStat
 *
 * Kitchen-sink stats component
 */
const props = withDefaults(defineProps<StatProps>(), {
  variant: 'inline',
  align: 'left',
  value: undefined,
})

defineEmits(['action'])

const slots = useSlots()

const alignClasses = {
  left: 'justify-start text-left',
  center: 'justify-center text-center',
  right: 'justify-end text-right',
}

const hasInfo = computed(() => {
  return !!(slots.info || props.info)
})

const ui = computed(() => {
  return {
    container: props.variant === 'card'
      ? 'rounded-lg p-5 border border-neutral-600 bg-white'
      : '',
    align: alignClasses[props.align],
    gap: hasInfo.value
      ? 'space-y-5'
      : 'space-y-2',
  }
})

/// props component rendering
function isMaybeComponent(value) {
  return isVNode(value) || typeof value === 'function'
}

function renderComponent(value) {
  return typeof value === 'function'
    ? value()
    : value
}

/// loading
const middle = ref<HTMLDivElement | null>(null)
const shimmer = ref<HTMLDivElement | null>(null)

function positionShimmer() {
  if (middle.value) {
    // const mt = middle.value.computedStyleMap().get('margin-top').value
    const x = middle.value.offsetLeft
    const y = middle.value.offsetTop
    const w = middle.value.clientWidth
    const h = middle.value.clientHeight
    Object.assign(shimmer.value.style, {
      width: ` ${w}px`,
      height: `${h}px`,
      top: `   ${y}px`,
    })
  }
}

watch(() => props.loading, positionShimmer)

onMounted(positionShimmer)
</script>

<template>
  <div
    data-ui="UiStat"
    :data-loading="loading ? 1 : 0"
    :class="[ui.container, ui.align, ui.gap]"
    class="relative text-gray-600 flex-grow grow-1 overflow-hidden"
  >
    <!-- label + tooltip -->
    <slot name="label">
      <div class="flex flex-row items-center gap-1" :class="ui.align">
        <UiIcon v-if="icon" size="md" :name="icon" />
        <UiLabel class="text-xs" :text="title" :tooltip="tooltip" />
      </div>
    </slot>

    <!-- middle and bottom row -->
    <div ref="middle" class="shimmer-content flex flex-row gap-2 transition-opacity">
      <!-- left slot -->
      <component :is="left" v-if="isVNode(left)" />
      <div v-else-if="$slots.left" class="shrink-0">
        <slot name="left" />
      </div>

      <!-- value + right slot -->
      <div class="flex flex-col w-full gap-1">
        <!-- default slot -->
        <slot>
          <div class="flex flex-row items-center gap-2" :class="ui.align">
            <slot name="value">
              <UiFormat
                :size="hasInfo ? 'lg' : 'md'"
                :value="value"
                :format="format"
                class="leading-none text-primary-900"
              />
            </slot>
            <component :is="renderComponent(right)" v-if="isMaybeComponent(right)" />
            <slot v-else-if="$slots.right" name="right" />
          </div>
        </slot>

        <!-- bottom + edit -->
        <div
          v-if="hasInfo"
          class="flex flex-row justify-between items-center w-full h-4 gap-2 text-xs"
          :class="align === 'right' ? '!flex-row-reverse' : ''"
        >
          <!-- info -->
          <div class="flex items-center gap-1 text-xs">
            <slot v-if="$slots.info || info" name="info">
              {{ info }}
              <template v-if="info && meta">
                :
              </template>
              <component :is="renderComponent(meta)" v-if="isMaybeComponent(meta)" />
              <span v-else-if="meta" class="font-semibold">{{ meta }}</span>
            </slot>
          </div>

          <!-- edit -->
          <slot v-if="$slots.action || action" name="action">
            <component :is="renderComponent(action)" v-if="isMaybeComponent(action)" />
            <UiButton size="xs" :icon="action" @click="$emit('action')" />
          </slot>
        </div>
      </div>
    </div>

    <!-- loading shimmer -->
    <div ref="shimmer" class="shimmer absolute t-0 !m-0 p-0 space-y-2.5 transition-opacity select-none">
      <div class="h-5 w-40 bg-gray-300 rounded-xl animate-pulse" />
      <div v-if="hasInfo" class="h-3 w-24 bg-gray-300 rounded-xl animate-pulse" />
    </div>
  </div>
</template>

<style scoped>
.shimmer-content {
  transition-duration: 300ms;

  [data-loading="1"] & {
    opacity: 0;
    transition-delay: 0ms;
  }

  [data-loading="0"] & {
    transition-delay: 150ms;
    opacity: 1;
  }
}

.shimmer {
  pointer-events: none;
  transition-duration: 300ms;

  [data-loading="1"] & {
    opacity: 1;
    transition-delay: 150ms;
  }

  [data-loading="0"] & {
    opacity: 0;
    transition-delay: 0ms;
  }
}
</style>
